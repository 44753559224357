<template>
	<div class="order_wrap">
		<v-container class="fixed_wrap">
			<MStepper />
			<h1 class="tit_page px-6">고객 직접 납부 및 전자계약 서명 진행</h1>
			<p class="txt_body1 px-6 mb-8">
				고객 직접 납부로 진행하시면 고객님께 납부 및 전자계약 서명 문자가
				발송되어, 수납금 납부부터 전자 계약서 서명까지 고객이 직접 주문 확정을
				할 수 있습니다.
			</p>
			<h3 class="bg_gray_tit mb-6">계약 내용 확인</h3>
			<div class="txt_box_01 px-6">
				<div class="txt_type_01">
					<ul class="info_list my-5">
						<li>
							<dt>고객명</dt>
							<dd>{{ name }}</dd>
						</li>
						<li>
							<dt>전화번호</dt>
							<dd>{{ getPhone(phone) }}</dd>
						</li>
						<li>
							<dt>주문번호</dt>
							<dd>{{ orderNo }}</dd>
						</li>
					</ul>
					<div class="inner_box my-0">
						<div class="summay_list">
							<dl class="impact">
								<dt>
									주문 요약
								</dt>
								<dd>
									총 <strong>{{ orderList.length }}</strong
									>개
								</dd>
							</dl>
							<template v-for="(sum, i) in groupbySumList">
								<dl :key="i">
									<dt>
										{{ getPrdName(sum.code) }}
									</dt>
									<dd>{{ sum.sale }} / {{ sum.cnt }}개</dd>
								</dl>
							</template>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="bottom_box">
				<v-btn color="primary" class="btn_col2 col-12" @click="send()">
					계약서 발송
				</v-btn>
			</div> -->
			<div class="bottom_box">
				<!-- <v-btn color="" class="btn_col2 col-4 sub" @click="onResetPayMethod">
					납부방법 재선택
				</v-btn> -->
				<v-btn color="primary" class="btn_col2 col-12" @click="alimTalk">
					납부 및 서명 안내 문자 발송
				</v-btn>
			</div>
		</v-container>
	</div>
</template>

<script>
import { fetchEContract, sendAlimTalk } from '@/apis/order.ec.api'
import {
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
import filters from '@/mixins/filters'
import { getSellType, getPrdName, groupbyOrderInfo } from '@/utils/ec'
import { registerCallbackToApp } from '@/utils/navigation'
import { getPhone } from '@/utils/ec'
export default {
	mixins: [filters],
	props: {},
	data() {
		return {
			custSelf: false,
			name: '',
			phone: '',
			orderNo: '',
			validDate: '',
			orderList: [],
			sumList: [],
			groupbySumList: [],
			webOrderSum: {},
			sum: {},
			webParams: {}
		}
	},
	created() {
		this.initAppPage()
	},
	async mounted() {
		this.custSelf = this.$store.getters['verify/custSelf']
		this.name = this.$store.getters['verify/custName']
		this.phone = this.$store.getters['verify/custPhone']

		// 계약확인 조회
		const params = {
			mobOrderNo: this.$store.getters['verify/mobOrderNo']
		}

		const info = await fetchWebOrderInfoById(params.mobOrderNo)
		this.webParams = info.resultObject
		this.sum = JSON.parse(this.webParams.orderInfoJson)
		this.webOrderSum = this.sum.PAY_SUMMARY_AMT

		const res = await fetchEContract(params)
		this.orderList = res.resultObject.data[0].setup
		this.sumList = res.resultObject.data[0].sum

		let ordNoStr = ''
		this.orderList.forEach((res, i) => {
			if (i === 0) {
				ordNoStr = `${res.ORDER_NO}`
			} else {
				ordNoStr = `${ordNoStr}, ${res.ORDER_NO}`
			}
		})
		this.orderNo = ordNoStr

		// 주문요약 셋팅
		this.groupbySumList = await groupbyOrderInfo(this.sumList, this.orderNo)
	},
	methods: {
		getSellType,
		getPrdName,
		getPhone,
		goBackPage() {
			this.$router.go(-1)
		},
		initAppPage() {
			registerCallbackToApp({})
		},
		async alimTalk() {
			// this.$log('this.sum', this.sum)
			let info = this.sum
			let ecParams = { mobOrderNo: this.$store.getters['verify/mobOrderNo'] }
			const res = await fetchEContract(ecParams)
			const orderList = res.resultObject.data[0].setup
			const sumAmt = res.resultObject.data[0].sumAmt
			sumAmt.MONTH_AMT_DC = info.MONTH_AMT_DC
			sumAmt.MONTH_AMT_TOTAL = sumAmt.MONTH_AMT - sumAmt.MONTH_AMT_DC

			const webOrderSum = info.PAY_SUMMARY_AMT

			let prdNm = orderList[0] ? orderList[0].GOODS_NM : '제품명'
			let count = orderList && orderList.length > 1 ? orderList.length - 1 : 0
			let planDt = orderList[0].FIRST_DT ?? ''

			let ordNoStr = orderList[0] ? orderList[0].ORDER_NO : '주문번호'
			// orderList.forEach((res, i) => {
			// 	if (i === 0) {
			// 		ordNoStr = `${res.ORDER_NO}`
			// 	} else {
			// 		ordNoStr = `${ordNoStr}, ${res.ORDER_NO}`
			// 	}
			// })

			const mobOrderNo = this.$store.getters['verify/mobOrderNo'] // 웹주문번호
			let atParams = {
				reqCd: 3, // 1 : 계약서발송, 2 : 주문완료, 3 : 서명완료재진입
				gubun: '1', // 1 : 고객직접, 2 : 코디대행
				name: this.$store.getters['verify/custName'],
				phone: this.$store.getters['verify/custPhone'],
				orderNo: count > 0 ? `${ordNoStr} 외 ${count}건` : `${ordNoStr}`,
				orderInfoId: mobOrderNo,
				customerNo: this.$store.getters['verify/custNo'],
				codyNo: this.$store.getters['common/codyNo'],
				codyPhoneNo: this.$store.getters['common/codyPhoneNo'],
				goodsNm: prdNm,
				goodsInfo: count > 0 ? `${prdNm} 외 ${count}대` : `${prdNm}`,
				onceAmt: this.$options.filters.comma(webOrderSum.totalSumOfRecpAmt),
				monthAmt: this.$options.filters.comma(webOrderSum.totalSumOfMonthAmt),
				planDt: planDt
			}

			atParams.url = `${process.env.VUE_APP_FRT_URL}/order/ec/cust/pass` // 고객납부 링크

			await sendAlimTalk(atParams)

			info = Object.assign(info, {
				recp: {
					recpParams: this.$store.getters['recp/recpParams'],
					recpInfos: this.$store.getters['recp/recpInfos'],
					isCustSelfPay: true,
					payRecp: this.$store.getters['recp/payRecp']
				}
			})
			this.webParams.step = '04' // 수납정보
			this.webParams.statusCode = '105' // 수납입력
			this.webParams.orderInfoJson = JSON.stringify(info)
			await postWebOrderInfoById(mobOrderNo, this.webParams)
			let alimChk = await this.$alert({
				message:
					'납부 및 전자계약 서명 문자를 발송하였습니다.<br/>주문 현황으로 이동합니다.',
				okText: '확인'
			})
			if (alimChk) {
				this.$router.push({ name: 'order-status' })
			}
		},
		onResetPayMethod() {
			this.$router.go(-1)
		}
	}
}
</script>

<style scoped lang="scss"></style>
